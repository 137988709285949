<template>
  <select class="form-control" :key="municipios.length" :value="municipio" @change="$emit('update:municipio', $event.target.value)">
    <option value="" disabled>Seleccionar Municipio</option>
    <option v-for="_municipio in municipios" :key="_municipio.idMunicipio" :value="_municipio.idMunicipio">{{_municipio.municipio}}</option>
  </select>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";

export default defineComponent({
  name: "SelectMunicipio",
  props: {
    estado: {
      type: String,
      default: ''
    },
    municipio: {
      type: String,
      default: ''
    }
  },
  setup(props){

    const { loading, make } = useApi();
    const municipios = ref([]);

    watch(() => props.estado, () => {
      getMunicipios();
    });

    async function getMunicipios(){
      loading.message = 'Obteniendo municipios';
      let {data} = await make('generales/get-municipios', {idEstado: props.estado});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          municipios.value = data.municipios;
        }
      }
    }

    onMounted(() => {
      getMunicipios();
    });


    return {municipios};
  }
})
</script>

<style scoped>

</style>