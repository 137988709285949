<template>
  <select class="form-control" :value="superior" @change="$emit('update:superior', $event.target.value)" required>
    <option value="" selected disabled>{{placeholder}}</option>
    <option value="-1" v-if="all">Todos</option>
    <option value="0" v-if="isAdmin">Personal Administrativo</option>
    <optgroup label="Operaciones" v-if="isAdmin">
      <option v-for="user in operaciones" :key="user.id" :value="user.id">{{user.name}} {{user.lastname}}</option>
    </optgroup>
    <optgroup label="Coordinadores" v-if="isAdmin || isCoordinador">
      <option v-for="user in coordinadores" :key="user.id" :value="user.id">{{user.name}} {{user.lastname}}</option>
    </optgroup>
    <optgroup label="Supervisores">
      <option v-for="user in supervisores" :key="user.id" :value="user.id">{{user.name}} {{user.lastname}}</option>
    </optgroup>
  </select>
</template>

<script>
import {defineComponent, ref} from "vue";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";
import useAuth from "@/providers/auth";

export default defineComponent({
  name: "SelectSuperior",
  props: {
    superior: {
      type: String,
      default: ''
    },
    all: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Seleccionar Superior'
    }
  },
  setup(){

    const { loading, make } = useApi();

    const { isAdmin, isCoordinador } = useAuth();

    const operaciones = ref([]);
    const coordinadores = ref([]);
    const supervisores = ref([]);

    async function getOperaciones(){
      loading.message = 'Obteniendo información';
      let {data} = await make('generales/get-operaciones', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          operaciones.value = data.operaciones;
        }
      }
    }

    async function getCoordinadores(){
      loading.message = 'Obteniendo información';
      let {data} = await make('generales/get-coordinadores', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          coordinadores.value = data.coordinadores;
        }
      }
    }

    async function getSupervisores(){
      loading.message = 'Obteniendo información';
      let {data} = await make('generales/get-supervisores', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          supervisores.value = data.supervisores;
        }
      }
    }

    getOperaciones();
    getCoordinadores();
    getSupervisores();

    return {operaciones, coordinadores, supervisores, isAdmin, isCoordinador};
  }
})
</script>

<style scoped>

</style>