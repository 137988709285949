<template>
  <select class="form-control" :value="estado" @change="$emit('update:estado', $event.target.value)">
    <option value="" disabled>Seleccionar Estado</option>
    <option v-for="_estado in estados" :key="_estado.idEstado" :value="_estado.idEstado">{{_estado.estado}}</option>
  </select>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import Swal from "sweetalert2";
import {useApi} from "@/use/useApi";

export default defineComponent({
  name: "SelectEstado",
  props: {
    estado: {
      type: String,
      default: ''
    }
  },
  setup(){

    const { loading, make } = useApi();
    const estados = ref([]);

    async function getEstados(){
      loading.message = 'Obteniendo estados';
      let {data} = await make('generales/get-estados', {});
      if(data === null){
        Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if(data.error){
          Swal.fire('Error', data.message, 'error');
        } else {
          estados.value = data.estados;
        }
      }
    }

    onMounted(() => {
      getEstados();
    })


    return {estados};
  }
})
</script>

<style scoped>

</style>